import React from 'react'

const Settings = () => {
  return (
    <div className="body mt-[3%] mb-[5%] mx-[15%] min-h-[40vh] flex items-center">
        <h1 className='text-5xl text-default font-semibold'>Settings Page comming Soon...!</h1>
    </div>
  )
}

export default Settings
